import React from 'react'

import port1 from './img/home-interior-designs.jpg'

import port2 from './img/port2.jpg'

import port3 from './img/port3.jpg'

import port4 from './img/port4.jpg'

import port5 from './img/port5.jpg'

import port6 from './img/port6.jpg'


export default function Portfolio() {
  return (
    <section className='py-5 bg-light mt-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mb-4">
                    <h2 data-aos="fade-up">Our Portfolio</h2>
                    <p data-aos="fade-up">We have done quality projects in variety of sector around the globe.</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={port1} alt="" className="img-fluid rounded-3" />
                    </figure>
                    
                    <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 1</h4>
                    </div>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={port2} alt="" className="img-fluid rounded-3" /></figure>
                    <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 2</h4>
                    </div>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={port3} alt="" className="img-fluid rounded-3" /></figure>
                    <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 3</h4>
                    </div>
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={port4} alt="" className="img-fluid rounded-3" /></figure>
                    <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 4</h4>
                    </div>
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img  src={port5} alt="" className="img-fluid rounded-3" /></figure>
                    <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 5</h4>
                    </div>
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={port6} alt="" className="img-fluid rounded-3" /></figure>
                    <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 6</h4>
                    </div>
                </div>
                <div className="col-md-12  justify-content-center text-center">
                    <a href="#"><button className="custom-btn btn-12"><span>Load More !</span><span>View More !</span></button></a>
                </div>
            </div>
        </div>
    </section>
  )
}
