import './App.css';
import{BrowserRouter, Routes, Route} from 'react-router-dom';

import Navbar from './components/Navbar';
import SliderSec from './components/SliderSec';


import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Contact from './components/Contact';

import PartnersAf from './components/PartnersAf';



import ServiceSlider from './components/ServiceSlider';
import HowWeWork from './components/HowWeWork';

import RecentPost from './components/RecentPost';

import ContactUs from './components/ContactUs';
import Testimonial from './components/Testimonial';





import Appointment from './components/Appointment';
import Portfolio from './components/Portfolio';








function App() {
  return (
      
      <BrowserRouter>     
        <Navbar/>
        
        <Routes>
          <Route exact path='/' element={
            <>
                      
                <SliderSec/>                    
              
              <PartnersAf />
              
              <ServiceSlider />
             
              
              <Portfolio></Portfolio>
              
              
              <HowWeWork />
              
            
              
              <ContactUs />
              <Testimonial></Testimonial>
              
              
            </>
          } />
          

          

           

         

             

          <Route path='privacy-policy' element={
            <>
              <section className='bg-about'>
                <PrivacyPolicy/>
              </section>
            </>
          } />

          <Route path='terms-of-use' element={
            <>
              <section className='bg-about'>
                <Terms/>
              </section>
            </>
          } /> 

          <Route path='contact' element={
            <>             
              <Contact/>    
            </>
          } />

          
          
          
             

          <Route path='appointment' element={
            <>
              <Appointment></Appointment>
            </>
          } />     
          
          
          
        </Routes>
        
           
        <Footer></Footer> 
          
       </BrowserRouter> 
  );
}

export default App;
