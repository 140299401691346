import React from 'react'
import call from './img/bxs_phone-call.png'
import mail from './img/ic_sharp-email.png'
import location from './img/carbon_location-filled.png'
import twitter from './img/twitter.png'
import insta from './img/instagram.png'
import face from './img/facebook.png'
import linked from './img/linkedin.png'
import circle1 from './img/circle-1.png'
import circle2 from './img/circle-2.png'

export default function ContactUs() {
  return (
    <section className='contactUS bg-white py-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 data-aos="fade-up">Contact Us</h2>
                    <p data-aos="fade-up">Any question or remarks? Just write us a message!</p>
                </div>
            </div>

            <div className="row bg-light-1 contact-bg-1 align-items-center">
                <div className="col-md-5 position-relative">
                    <div className="bg-primary1-1 my-2 p-5">
                        <h3 data-aos="fade-up" className='text-white pt-5'>Contact Information</h3>
                        <p data-aos="fade-up" className='text-white'>Say something to start a live chat!</p>
                        <div className='d-flex py-4'>
                            <img data-aos="fade-up" src={call} alt="" className="img-fluid" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a data-aos="fade-up" className='text-white text-decoration-none' href='tel:+91 90959 49393'>+91 90959 49393</a>
                        </div>

                        <div className='d-flex py-4'>
                            <img data-aos="fade-up" src={mail} alt="" className="img-fluid" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a data-aos="fade-up" className='text-white text-decoration-none' href='mailto:info@a3ginterio.com'>info@a3ginterio.com</a>
                        </div>

                        <div className='d-flex py-4 mb-5'>
                            <img data-aos="fade-up" src={location} alt="" className="" height='100%' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a data-aos="fade-up" className='text-white text-decoration-none' href='https://maps.app.goo.gl/BMFerficbSbwr95fA' target='_blank'>H.No.8-2-622/4, Road No.10, Banjara hills, Hyderabad- 500 034, Hyderabad, Telangana 500034</a>
                        </div>

                        <ul className='list-unstyled pt-5 contact-us-home'>
                            <li>
                                <a href=""><img data-aos="fade-up" src={face} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href=""><img data-aos="fade-up" src={twitter} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href=""><img data-aos="fade-up" src={linked} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href=""><img data-aos="fade-up" src={insta} alt="" className="img-fluid" /></a>
                            </li>
                        </ul>
                    </div>

                    <img data-aos="fade-up" src={circle1} alt="" className="img-fluid circle1" />
                    <img data-aos="fade-up" src={circle2} alt="" className="img-fluid circle2" />
                   
                </div>
                <div className="col-md-7">
                <form className='formConnect' action='' method='POST' autoComplete='off'>
                    <div className="row">
                        <div className="form-group col-md-6 mt-5">
                            <label data-aos="fade-up" className='fw-bold' htmlFor="fname">First Name</label>
                            <input data-aos="fade-up" id="fname" name='fname' type="text" className="form-control" placeholder='' autoComplete='given-name' required />
                        </div>

                        <div className="form-group col-md-6 mt-5">
                            <label data-aos="fade-up" className='fw-bold' htmlFor="lname">Last Name</label>
                            <input data-aos="fade-up" id="lname" name='lname' type="text" className="form-control" placeholder='' autoComplete='family-name' required />
                        </div>

                        <div className="form-group col-md-6 mt-5">
                            <label data-aos="fade-up" className='fw-bold' htmlFor="email">Email</label>
                            <input data-aos="fade-up" id="email" name='email' type="email" className="form-control" placeholder='' autoComplete='email' required />
                        </div>

                        <div className="form-group col-md-6 mt-5">
                            <label className='fw-bold' htmlFor="phone">Phone Number</label>
                            <input type="text" id="phone" name='phone' className="form-control" placeholder='' autoComplete='tel' required />
                        </div>

                        <div className="form-group col-md-12 mt-5">
                            <label data-aos="fade-up" className='fw-bold' htmlFor="message">Message</label>
                            <input data-aos="fade-up" id="message" name='message' type="text" className="form-control" placeholder='Write your message' autoComplete='off' required />
                        </div>

                        <div className="form-group col-md-12 mt-5 text-end">
                            <button data-aos="fade-up" name='submit' className="btn btn-dark px-5 py-2">Send Message</button>
                        </div>
                    </div>
                </form>

                </div>
            </div>
        </div>
    </section>
  )
}
