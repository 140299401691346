import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
    <section className='bg-white'>
      <div className="container pt-5">
        <div className="row align-items-center text-center justify-content-center">              
            <div className="col-md-7">
                <h2>
                    Privacy Policy
                </h2>
                <p>
                  
                </p>
                <h6>For more info</h6>
                <button className='btn btn-danger'>Check Below Points <i className="bi bi-box-arrow-down"></i></button>
            </div>
        </div>
      </div>
    </section>
    
    <section className=' bg-white'>
        <div className="container">
            <div className="row align-items-center justify-content-center py-5" >
                <div className="col-md-10 py-5">
                  
                <h4>1. Information Collection:</h4>
                 
                  <p>Updating Soon</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
