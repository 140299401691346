import React from 'react'
import logo from './img/logo.png'
import email from './img/email.png'
import { Link } from 'react-router-dom'
import twitter from './img/twitter.png'
import insta from './img/instagram.png'
import face from './img/facebook.png'
import linked from './img/linkedin.png'

export default function Footer() {
  return (
    <>
    <footer className='bg-light border-top bg-dark'>
        <div className="container pt-5">
          <div className="row border-secondary align-items-center border-bottom pb-3">
            <div className="col-md-3">
              <a href='/'>
                <img  src={logo} alt="logoImage" className="img-fluid logo-f" />
              </a>
            </div>
            <div className="col-md-4 mpy-4">
              <h4 className='text-white mb-0 pb-0'>Subscribe Newsletter</h4>
            </div>
            <div className="col-md-5">
              <div className="row subscribe bg-white p-2">
                <div className="col-7  col-md-9">
                  <div className="input-group">
                    <span className="input-group-text" id="email">
                      <img src={email} alt="email" className="img-fluid" />
                    </span>
                    <input type="email" autoComplete='off' name='email' className="form-control border-0 " placeholder="Email" aria-label="email" aria-describedby="email" />
                  </div>
                </div>
                <div className='col-5 col-md-3'>
                  <button name='submit' className='btn btn-primary border-0'>Subscribe&nbsp;<span className=''>&#8250;</span></button>
                </div>
                
                
              </div>
            </div>
          </div>
          <div className='row py-4'>
            <div className="col-md-5">
              <h5 className='text-white'>About Company</h5>
              <p className='text-secondary-1'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
            </div>
            <div className="col-md-4">
              <h5 className='text-white'>Quick Links</h5>
              <ul className='text-dec-none ps-0' id='QuickLinks'>
                <li className='pb-2 pt-2'><Link className='text-secondary-1' to="./"><i className="bi bi-chevron-compact-right"></i>&nbsp;Home</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Services</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;About</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Blog</Link></li>
                

                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Contact</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Appointment</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Terms of Use</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary-1' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Privacy Policy</Link></li>
              </ul>
            </div>
            <div className="col-md-3"> 
              
              <div className='row mpx-3'>
                <div className='col-md-12 p-0'>
                  <h5 className='text-white'>Contact</h5>
                  <p className='mb-0 pb-2 text-secondary-1 d-flex'><i className="bi bi-geo-alt-fill text-secondary-1 "></i>H.No.8-2-622/4, Road No.10, Banjara hills, Hyderabad- 500 034, Hyderabad, Telangana 500034</p>
                  <p className='mb-0 pb-2 d-flex'><a className='text-secondary-1 text-decoration-none' href='tel:+91 9095949393'><i className="bi bi-telephone-forward"></i> +91 90959 49393 </a></p>
                  <p className='mb-0 pb-2 d-flex'><a className='text-secondary-1 text-decoration-none' href='mailto: info@a3ginterio.com'><i className="bi bi-envelope"></i> info@a3ginterio.com </a></p>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
    </footer>
    <section className='bg-dark-1 footerLast'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <p className='mt-3 text-light m-text-center'>Copyright © 2024 <b className='primary-text'>A3G Interio</b> | All Rights Reserved</p>
          </div>
          <div className="col-md-4 text-end footerBottom">
                <li>
                    <a href=""><img  src={face} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img  src={twitter} alt="" className="img-fluid bg-white rounded-2" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img  src={linked} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img  src={insta} alt="" className="img-fluid" /></a>
                </li>
          </div>
          
        </div>
      </div>
    </section>
    </>
  )
}
